import { Base64 } from "../../types";

export const removeSpaces = (value: string): string => {
  return value.replace(/[ ]+/g, "");
};

export const decodeBase64 = (base64String: Base64): Buffer => {
  return Buffer.from(base64String, "base64");
};

export const createSafeFileName = (name: string) => {
  const date = new Date().toLocaleString("en-US").replace(/:/g, "-");
  const fileName = `${name}-${date}`;
  const safeFileName = fileName
    .replace(/\s/g, "_")
    .replace(/[,\/\\:*?"<>|]/g, "_");
  return safeFileName;
};
